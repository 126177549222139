var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{staticClass:"p-4 border-bottom-light-grey min-height-57px"},[_c('v-flex',{staticClass:"font-level-3-bold my-auto",attrs:{"md5":""}},[_c('span',{staticClass:"detail-svg-icon mr-2"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/reservation-color.svg')}})],1),_vm._v(" "+_vm._s(_vm.getTitle())+" ")]),(false)?_c('v-flex',{staticClass:"font-level-3-bold my-auto d-flex justify-content-end align-items-center",attrs:{"md7":""}},[_c('span',{staticClass:"mr-1"},[_vm._v("AMOUNT ")]),_c('span',{staticClass:"blue--text text--darken-4",staticStyle:{"font-size":"24px"}},[_vm._v("$200")])]):_vm._e()],1),(_vm.contentLoading)?[_c('Loading')]:_c('div',{staticClass:"overflow-y",staticStyle:{"max-height":"calc(100vh - 306px)"}},[_c('table',{staticClass:"detail-table table-head-sticky",attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"p-2 blue lighten-4 text-center",attrs:{"width":"60"}}),_c('th',{staticClass:"p-2 blue lighten-4 text-center",attrs:{"width":"100"}},[_vm._v("#")]),_c('th',{staticClass:"p-2 blue lighten-4",attrs:{"width":"100"}},[_vm._v("Item")]),_c('th',{staticClass:"p-2 blue lighten-4",attrs:{"width":"200"}},[_vm._v("Description")]),_c('th',{staticClass:"p-2 blue lighten-4",attrs:{"width":"150"}},[_vm._v("UOM")]),_c('th',{staticClass:"p-2 blue lighten-4 text-center",attrs:{"width":"150"}},[_vm._v("Qty")]),(
							_vm.statusvalue != 1 &&
							_vm.statusvalue != 3 &&
							_vm.statusvalue != 4 &&
							_vm.statusvalue != 5 &&
							_vm.statusvalue != 6 &&
							_vm.statusvalue != 7
						)?_c('th',{staticClass:"p-2 blue lighten-4 text-center",attrs:{"width":"120"}},[_vm._v(" Approved ")]):_vm._e()])]),(_vm.line_items.length)?[_c('Draggable',{staticClass:"draggable-group",attrs:{"tag":"tbody","handle":".draggable-drag-icon"},on:{"change":function($event){return _vm.updateLineItem()}},model:{value:(_vm.line_items),callback:function ($$v) {_vm.line_items=$$v},expression:"line_items"}},_vm._l((_vm.line_items),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"p-2 border-top-light-grey text-center"},[_c('v-icon',{staticClass:"cursor-move draggable-drag-icon",attrs:{"color":"blue darken-4 "}},[_vm._v("mdi-drag")])],1),_c('td',{staticClass:"p-2 border-top-light-grey text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"p-2 border-top-light-grey"},[_c('ShowValue',{attrs:{"object":row,"object-key":"product_name","label":"product name"}}),_c('div',{staticClass:"cursor-pointer"},[(row.product_status == 3)?_c('v-chip',{attrs:{"x-small":"","label":"","color":"red","text-color":"white"},on:{"click":function($event){return _vm.LineItemDetail(row.product_uuid)}}},[_c('span',{staticClass:"fw-500"},[_vm._v("RPO")])]):_c('v-chip',{attrs:{"x-small":"","label":"","color":"green","text-color":"white"},on:{"click":function($event){return _vm.LineItemDetail(row.product_uuid)}}},[_c('span',{staticClass:"fw-500"},[_vm._v("Existing")])])],1)],1),_c('td',{staticClass:"p-2 border-top-light-grey"},[_c('ShowValue',{attrs:{"object":row,"object-key":"description","label":"description"}})],1),_c('td',{staticClass:"p-2 border-top-light-grey"},[_c('ShowValue',{attrs:{"object":row,"object-key":"uom","label":"uom"}})],1),_c('td',{staticClass:"p-2 border-top-light-grey text-center"},[_vm._v(" "+_vm._s(row.quantity ? Number(row.quantity).toFixed(2) : 0)+" ")]),(
								_vm.statusvalue != 1 &&
								_vm.statusvalue != 3 &&
								_vm.statusvalue != 4 &&
								_vm.statusvalue != 5 &&
								_vm.statusvalue != 6 &&
								_vm.statusvalue != 7
							)?_c('td',{staticClass:"p-2 border-top-light-grey text-center"},[_vm._v(" "+_vm._s(row.approve_quantity ? Number(row.approve_quantity).toFixed(2) : 0)+" ")]):_vm._e(),(false)?_c('td',{staticClass:"p-2 border-top-light-grey"},[_c('InputEdit',{on:{"update":function($event){return _vm.updateValue($event)}},model:{value:(row.recieved),callback:function ($$v) {_vm.$set(row, "recieved", $$v)},expression:"row.recieved"}}),(false)?_c('div',{staticClass:"mx-auto",staticStyle:{"width":"60px"}},[_c('TextInput',{staticClass:"mt-0",attrs:{"hide-details":""},model:{value:(row.recieved),callback:function ($$v) {_vm.$set(row, "recieved", $$v)},expression:"row.recieved"}}),_c('div',{staticClass:"w-100 px-3 py-1"},[_vm._v(_vm._s(row.recieved))])],1):_vm._e()],1):_vm._e()])}),0),_c('tfoot',{staticStyle:{"font-size":"14px"}})]:_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('p',{staticClass:"m-0 row-not-found text-center py-3"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There "),_c('span',{staticClass:"text-lowercase"},[_vm._v("are no "+_vm._s(_vm.getTitle())+" at the moment.")])])])])])],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }